body {
    background: #fff;
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    cursor: default;
    min-width: 850px;
}

a {
    color: #6f3775;
    text-decoration: none;
    text-decoration-color: -moz-use-text-color;
    text-decoration-line: none;
    text-decoration-style: solid;
    line-height: inherit;
}

ul {
    list-style: none;
}

pre {
  line-height: normal;
  font-family: Palatino,Helvetica,Arial,sans-serif;
}
pre ul { list-style: initial; line-height: 12px; }

.centrar { text-align: center; }

.Ttex1 { font-size: 18px; } /*18px*/
.Ttex2 { font-size: 19px; }
.Ttex3 { font-size: 21px; }
.Ttex4 { font-size: 25px; }

/*<<<

.BlPagApps { text-align: left; }
.BlPagApps td { padding: 0px 3px; }

/*<<<
.row-full {
    max-width: 87.5em;
    margin: 0 auto;
    width: 100%;
    clear: both;
}

.row::before, .row::after {
    content: " ";
    display: table;
}
*/