.PiePagina {
    position: relative;
    width: 100%;
    bottom: 0;
    background: #4238af;
    color: white;
    opacity: 0.3;  /* Opacidad,para Todos los Navegadores menos Explorer */
    /* filter: alpha(opacity=30);  /* Opacidad, para Explorer */
 }
 .CopyRigth { text-align: left; }
 
 .PiedePagina { text-align: center; }
 .PiedePagina a { color: #FFF; }