.TransImg-AutoPubli {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* border: 3px solid green; */
}
.TransImg-AutoPubli img {
    /*height: 250px;*/
    width: 900px;
}