.BlPagIni { text-align: left; }
.BlPagIni .Titulo { font-size: 40px; }
.BlPagIni td { padding: 0px 20px; }
.BlPagIni td div { padding: 0px 20px; width: min-content; }

.BlAutoPubli {
    position: relative;
    text-align: center;
    font-size: 30px;
}
.BlAutoPubli img { 
    position: relative;
    top: 10px;
    border: 10px solid #fff;
    border-radius: 122px;
    height: 240px;
    width: 240px
}
.BlPagIni .Descarga {
  margin-left: auto;
  margin-right: auto;
  width: min-content;
}

.ListCascada {
  position: relative;
  left: -17px;
  min-width: 900px;
}

.ListCascada li {
  padding: 40px 10px 40px 10px;
}
.ListCascada li.Der {
  background: #f5eff6;
}
.ListCascada li.Izq {
  background: #fff;
}

.ListCascada li.Der .Imagen {
  float: right;
  padding: 10px 50px;
}

.ListCascada li.Izq .Imagen {
  float: left;
  padding: 10px 50px;
}

.ListCascada li.Der .Titulo {
  position: relative;
  top: 10px;
  left: -60px;
}
.ListCascada li.Izq .Titulo {
  padding: 20px 0px;
}

.ListCascada li.Der .Descrip {
  text-align: left;
  padding: 30px 0 0 10px;
}
.ListCascada li.Izq .Descrip {
  text-align: left;
}

.ListCascada li.Der .Imagen img {
  position: relative;
  top: -50px;
  border: 10px solid #fff;
  border-radius: 122px;
  height: 220px;
  width: 220px
}
.ListCascada li.Izq .Imagen img {
  position: relative;
  top: -35px;
  height: 200px;
  width: 200px
  /* border: 10px solid #f5eff6; 
  border-radius: 122px; */  
}

.ListCascada li .Titulo > * {
  font-size: 35px;
  font-weight: 300;
}