.SelecIdio {
    position: absolute;
    z-index: 3;
    right: 200px;
    top: 18px;
}
.SelecIdioMovVert {
    z-index: 3;
    right: 200px;
    top: 18px;

    position: fixed;
    min-width: 1000px;
    margin: 0px -850px 0px 0px;
}

.ImgFd_SelecIdio {   
   background-image: url("/public/assets/imgs/fondo/SiluetaBoton.png");
   width: 160px; height: 43px; text-align: center; padding: 14px 0px 0px 0px;
}

.SelecIdio select, .SelecIdioMovVert select{
   background-color: transparent;
   border: 0 none;
   color: #686868;
   font-size: 15px;
   width: 100px;
}
/* #linea { width: 83%; } */