.ContCuerpoPag {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
    margin: 30px auto 30px;
    padding: 30px;
    text-align: center;
    background: #f5eff6;
    border-radius: 20px;
    /* border: 1px solid blue; */    
}

.PubliGoogle {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
}