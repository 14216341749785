Header {
    background: #fff;
    height: 130px;
    width: 800px;
    min-width: 850px;
    border-bottom: 1px solid #efeef0;
}

.Cabecera {
    margin: 0 12%;
}

.LogoWeb a {
    display: inline-block;
    padding-top: 23px;
}

.LogoWeb img {
    display: block;
    margin-top: 0;
}

.MenuCabecera {
    position: absolute;
    z-index: 1;
    right: 40px;
    top: 60px;
    width: max-content;
}

.MenuCabeceraMovVert {
    z-index: 1;
    right: 40px;
    top: 60px;
    width: max-content;

    position: fixed;
    min-width: 1000px;
    margin: 0px -570px 0px 0px;
}

.MenuCabecera li,
.MenuCabeceraMovVert li {
    float: left;
    padding: 8px 30px 8px 0px;
}

.MenuCabecera a,
.MenuCabeceraMovVert a {
    font-size: 20px;
    color: #6f3775;
    position: relative;
}

.Navega a.active::after,
.Navega a:hover::after {
    height: 4px;
    content: "";
    position: absolute;
    background: #e7d2eb;
    width: 100%;
    bottom: -8px;
    left: 0;
    border-radius: 5px;
    /* -webkit-border-radius: 5px;
    -moz-border-radius: 5px; */
}

.LinkRedesSociales {
    position: absolute;
    top: 22px;
    right: 68px;
    letter-spacing: -3px;
}

.LinkRedesSocialesMovVert {
    top: 22px;
    right: 68px;
    letter-spacing: -3px;

    position: fixed;
    min-width: 1000px;
    margin: 0px -900px 0px 0px;
}

.LinkRedesSociales img {
    padding: 2px;
}