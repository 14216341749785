.BlPagIni { text-align: left; }
.BlPagIni td { padding: 20px; }
.BlPagIni td div { padding: 20px; width: auto; }

.BlPagInicioApps {
    text-align: center;
}
.BlPagInicioApps td { padding: 20px; }
.BlPagInicioApps .RefApps { padding: 20px; }
.BlPagInicioApps .RefApps img { 
    position: relative;
    top: 10px;
    border: 5px solid #fff;
    border-radius: 122px;
    width: min-content; 
    height: min-content;
    /*height: 240px;
    width: 240px */
}