.BlPagSelecVideoTuto td { padding: 0px 3px; }

.RefApps {
  font-size: 17px;
  text-align: center;
  padding: 20px 25px;
}

.RefApps img {
    position: relative;
    top: 10px;
    border: 10px solid #fff;
    border-radius: 122px;
    height: 200px;
    width: 200px
}

.BlPagVideoTuto { text-align: center; }
.BlPagVideoTuto .Titulo { font-size: 35px; }
.BlPagVideoTuto td { padding: 0px 20px; }
.BlPagVideoTuto td div {
  padding: 0px 20px;
  width: 450px;
  position: relative;
  height:0; 
  padding-bottom:56.25%;
}

.BlPagVideoTuto iframe{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
}